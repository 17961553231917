<template>
  <div class="form-container">
    <img src="../assets/eplogoo.png" style="width: 200px; height: auto;">
      
    <h1>EP REAL ESTATE</h1>
    <h2>TENANT'S ACQUAINTANCE FORM</h2>
    <form @submit.prevent="confirmSubmission">

      <div class="form-group">
        <label for="passport">Upload your passport here:</label>
        <input type="file" id="passport" @change="handleFileUpload($event,'passport')" required>
      </div>
      <div class="form-group">
        <label for="applicantName">1. APPLICANT'S NAME:</label>
        <input type="text" id="applicantName" v-model="form.applicantName" required>
      </div>
      <div class="form-group">
        <label for="address">2. PRESENT RESIDENTIAL ADDRESS:</label>
        <input type="text" id="address" v-model="form.address" required>
      </div>
      <div class="form-group">
        <label for="stateOfOrigin">3. STATE OF ORIGIN:</label>
        <input type="text" id="stateOfOrigin" v-model="form.stateOfOrigin" required>
      </div>
      <div class="form-group">
        <label for="homeTown">4. HOME TOWN:</label>
        <input type="text" id="homeTown" v-model="form.homeTown" required>
      </div>
      <div class="form-group">
        <label for="religion">5. RELIGION:</label>
        <input type="text" id="religion" v-model="form.religion" required>
      </div>
      <div class="form-group">
        <label for="placeOfWorship">6. PLACE OF WORSHIP:</label>
        <input type="text" id="placeOfWorship" v-model="form.placeOfWorship" required>
      </div>
      <div class="form-group">
        <label for="phoneNumber">7. TELEPHONE NUMBER:</label>
        <input type="tel" id="phoneNumber" v-model="form.phoneNumber" required>
      </div>
      <div class="form-group">
        <label for="email">8. E-MAIL ADDRESS:</label>
        <input type="email" id="email" v-model="form.email" required>
      </div>
      <div class="form-group">
        <label for="maritalStatus">9. MARITAL STATUS:</label>
        <input type="text" id="maritalStatus" v-model="form.maritalStatus" required>
      </div>
      <div class="form-group">
        <label for="occupation">10. OCCUPATION/PROFESSION:</label>
        <input type="text" id="occupation" v-model="form.occupation" required>
      </div>
      <div class="form-group">
        <label for="employer">11. NAME & ADDRESS OF PRESENT EMPLOYER:</label>
        <input type="text" id="employer" v-model="form.employer" required>
      </div>
      <div class="form-group">
        <label for="jobTitle">12. JOB TITLE/DESCRIPTION:</label>
        <input type="text" id="jobTitle" v-model="form.jobTitle" required>
      </div>
      <div class="form-group">
        <label for="yearsOfEmployment">13. NO. OF YEARS IN EMPLOYMENT:</label>
        <input type="text" id="yearsOfEmployment" v-model="form.yearsOfEmployment" required>
      </div>
      <div class="form-group">
        <label for="natureOfbusiness">14. IF SELF EMPLOYED, STATE NATURE OF BUSINESS:</label>
        <input type="text" id="natureOfbusiness" v-model="form.natureOfbusiness" required>
      </div>
      <div class="form-group">
        <label for="selectedDate">15. DATE ESTABLISHED:</label>
        <VueDatePicker v-model="form.selectedDate" :format="formatDate"/> 
      </div>
      <div class="form-group">
        <label for="incNumber">16. INCOPORATION NUMBER (FOR CORPORATE CLIENT):</label>
        <input type="text" id="incNumber" v-model="form.incNumber" required>
      </div>
      <div class="form-group">
        <label for="addOfbankers">17. NAME AND ADDRESS OF BANKERS:</label>
        <input type="text" id="addOfbankers" v-model="form.addOfbankers" required>
      </div>
      <div class="form-group">
        <label for="noOfdependants">18. NO OF DEPENDANTS:</label>
        <input type="text" id="noOfdependants" v-model="form.noOfdependants" required>
      </div>
      <div class="form-group">
        <label for="yearsInFormer">19. NO OF YEARS IN FORMER PLACE OF RESIDENCE:</label>
        <input type="number" id="yearsInFormer" v-model="form.yearsInFormer" required>
      </div>
      <div class="form-group">
        <label for="reasonsForleaving">20. REASONS FOR WANTING TO LEAVE YOUR CURRENT RESIDENCE:</label>
        <input type="text" id="reasonsForleaving" v-model="form.reasonsForleaving" required>
      </div>
      <div class="form-group">
        <label for="landlordName">21. NAME OF LANDLORD/MANAGING AGENT:</label>
        <input type="text" id="landlordName" v-model="form.landlordName" required>
      </div>
      <div class="form-group">
        <label for="landlordNumber">22. PHONE NUMBER OF LANDLORD/MANAGING AGENT :</label>
        <input type="tel" id="landlordNumber" v-model="form.landlordNumber" required>
      </div>
      <div class="form-group">
        <label for="nextOfkin">23. NEXT OF KIN:</label>
        <input type="text" id="nextOfkin" v-model="form.nextOfkin" required>
      </div>
      <div class="form-group">
        <label for="nextOfkinNumber">24. NEXT OF KIN PHONE NUMBER:</label>
        <input type="tel" id="nextOfkinNumber" v-model="form.nextOfkinNumber" required>
      </div>
      <div class="form-group">
        <label for="reference">25. FIRST REFERENCE:</label>
        <input type="text" id="reference" v-model="form.reference" required>
      </div>
      <div class="form-group">
        <label for="referenceNumber">26. REFEREE PHONE NUMBER:</label>
        <input type="tel" id="referenceNumber" v-model="form.referenceNumber" required>
      </div>
      <div class="form-group">
        <label for="referenceAdd">27. REFEREE ADDRESS:</label>
        <input type="text" id="referenceAdd" v-model="form.referenceAdd" required>
      </div>
      <div class="form-group">
        <label for="reference2">28. SECOND REFERENCE:</label>
        <input type="text" id="reference2" v-model="form.reference2" required>
      </div>
      <div class="form-group">
        <label for="referenceNumber2">29. SECOND REFEREE PHONE NUMBER:</label>
        <input type="tel" id="referenceNumber2" v-model="form.referenceNumber2" required>
      </div>
      <div class="form-group">
        <label for="referenceAdd2">30. SECOND REFEREE ADDRESS:</label>
        <input type="text" id="referenceAdd2" v-model="form.referenceAdd2" required>
      </div>    
      <div class="form-group">
        <label for="IdCard">Kindly upload your ID card:</label>
        <input type="file" id="IdCard" @change="handleFileUpload" required>
      </div>
      <div class="form-group">
        <label for="signature">Kindly upload your SIGNATURE:</label>
        <input type="file" id="signature" @change="handleFileUpload($event, 'signature')" required>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Swal from 'sweetalert2';

export default {
  components: {
    VueDatePicker,
  },
  data() {
    return {
      form: {
        applicantName: '',
        address: '',
        stateOfOrigin: '',
        homeTown: '',
        religion: '',
        placeOfWorship: '',
        phoneNumber: '',
        email: '',
        maritalStatus: '',
        occupation: '',
        employer: '',
        jobTitle: '',
        yearsOfEmployment: '',
        natureOfbusiness: '',
        selectedDate: '',
        incNumber: '',
        addOfbankers: '',
        noOfdependants: '',
        yearsInFormer: '',
        reasonsForleaving: '',
        landlordName: '',
        landlordNumber: '',
        nextOfkin: '',
        nextOfkinNumber: '',
        reference: '',
        referenceAdd: '',
        referenceNumber: '',
        reference2: '',
        referenceAdd2: '',
        referenceNumber2: '',
        signature: null,
        passport: null,
      },
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    },
    handleFileUpload(event, field) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form[field] = e.target.result;
      };
      reader.readAsDataURL(file);
    },
confirmSubmission() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to submit this form?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.generatePdf();
        }
      });
    },

    async generatePdf() {
      const pdf = new jsPDF();
      pdf.setFont("aptos","bold");

      // Load and add logo image
     try {    
        const logo = await this.loadImage(require('../assets/eplogoo.png'));
        const logoWidth = 60; // Scale down if necessary
        const logoHeight = 35; // Scale down if necessary
        pdf.addImage(logo, 'PNG', 5, 5, logoWidth, logoHeight);
      } catch (error) {
        console.error("Error loading logo image: ", error);
      }

      pdf.text("EP REAL ESTATE", 70, 20);
      pdf.text("TENANT'S ACQUAINTANCE FORM", 70, 30);
         pdf.setFont("aptos", "normal");

            if (this.form.passport) {
        pdf.addImage(this.form.passport, 'PNG', 170, 5, 30, 30);
      }


      let y = 50; // Starting y position

      const addText = (text) => {
        if (y > 280) {
          pdf.addPage();
          y = 10; // Reset y position
        }
        const lines = pdf.splitTextToSize(text, 180); // Wrap text within 180 units
        pdf.text(lines, 10, y);
        y += lines.length * 10;
      };

      const formFields = [
        { label: "1. APPLICANT'S NAME:", value: this.form.applicantName },
        { label: "2. PRESENT RESIDENTIAL ADDRESS:", value: this.form.address },
        { label: "3. STATE OF ORIGIN:", value: this.form.stateOfOrigin },
        { label: "4. HOME TOWN:", value: this.form.homeTown },
        { label: "5. RELIGION:", value: this.form.religion },
        { label: "6. PLACE OF WORSHIP:", value: this.form.placeOfWorship },
        { label: "7. TELEPHONE NUMBER:", value: this.form.phoneNumber },
        { label: "8. E-MAIL ADDRESS:", value: this.form.email },
        { label: "9. MARITAL STATUS:", value: this.form.maritalStatus },
        { label: "10. OCCUPATION/PROFESSION:", value: this.form.occupation },
        { label: "11. NAME & ADDRESS OF PRESENT EMPLOYER:", value: this.form.employer },
        { label: "12. JOB TITLE/DESCRIPTION:", value: this.form.jobTitle },
        { label: "13. NO. OF YEARS IN EMPLOYMENT:", value: this.form.yearsOfEmployment },
        { label: "14. IF SELF EMPLOYED, STATE NATURE OF BUSINESS:", value: this.form.natureOfbusiness },
        { label: "15. DATE ESTABLISHED:", value: this.formatDate(this.form.selectedDate) },
        { label: "16. INCOPORATION NUMBER (FOR CORPORATE CLIENT):", value: this.form.incNumber },
        { label: "17. NAME AND ADDRESS OF BANKERS:", value: this.form.addOfbankers },
        { label: "18. NO OF DEPENDANTS:", value: this.form.noOfdependants },
        { label: "19. NO OF YEARS IN FORMER PLACE OF RESIDENCE:", value: this.form.yearsInFormer },
        { label: "20. REASONS FOR WANTING TO LEAVE YOUR CURRENT RESIDENCE:", value: this.form.reasonsForleaving },
        { label: "21. NAME OF LANDLORD/MANAGING AGENT:", value: this.form.landlordName },
        { label: "22. PHONE NUMBER OF LANDLORD/MANAGING AGENT:", value: this.form.landlordNumber },
        { label: "23. NEXT OF KIN:", value: this.form.nextOfkin },
        { label: "24. NEXT OF KIN PHONE NUMBER:", value: this.form.nextOfkinNumber },
        { label: "25. REFERENCE:", value: this.form.reference },
        { label: "26. PHONE NUMBER OF REFEREE:", value: this.form.referenceNumber },
        { label: "27. ADDRESS OF REFEREE:", value: this.form.referenceAdd },
        { label: "28. SECOND REFERENCE:", value: this.form.reference2 },
        { label: "29. PHONE NUMBER OF SECOND REFEREE:", value: this.form.referenceNumber2 },
        { label: "30. ADDRESS OF SECOND REFEREE:", value: this.form.referenceAdd2 },
      ];

      formFields.forEach(field => addText(`${field.label} ${field.value}`));
      addText("DECLARATION: I hereby declare that all the information given on this form is correct and agree to abide with the terms and conditions as stated in the tenancy agreement.");

       

     addText("Signature")

if (this.form.signature) {
        y += 10; // Adding some space before signature
        if (y > 280) {
          pdf.addPage();
          y = 10; // Reset y position
        }
        pdf.addImage(this.form.signature, 'PNG', 10, y, 50, 20);
        y += 30; // Adjust y position to ensure there's space below the signature
      }

      // Adding the date below the signature
      addText(`Date: ${this.formatDate(new Date())}`);
     

      pdf.save('tenant-form.pdf');
       // Show success message and reload the page
      Swal.fire(
        'Submitted!',
        'Your form has been submitted.',
        'success'
      ).then(() => {
        window.location.reload();
      });
    },
    

    
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    },
  },
};
</script>

<style>
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.header-text {
  display: inline-block;
  vertical-align: top;
}
.logo {
  display: inline-block;
  width: 50px;
  height: auto;
  vertical-align: top;
}
.form-group {
  margin-bottom: 10px;
}
label {
  display: block;
  margin-bottom: 5px;
}
input[type="text"], input[type="email"], input[type="file"], input[type="tel"], input[type="number"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>
